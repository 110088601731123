import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify, faApple} from '@fortawesome/free-brands-svg-icons'
import { musicHolder } from '../styles/pages.module.scss'
import {motion } from 'framer-motion'
import Seo from '../components/utilities/Seo'

export default function MusicPage() {
  
  const articleVariants = {
    pre: {
      opacity:1,
      y:0
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
      staggerChildren: 0.3,
      type: 'tween',
      when: "afterChildren",
      }
    },
    post: {
      opacity:1,
      y:0,
      transition: {
        when: "afterChildren",
        staggerChildren: 0.1,
        type: 'tween',
      }
    }
  }

  const liVariants = {
    pre: {
      opacity:0,
      y:30,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'tween',
        duration:0.6,
      }
    },
    post: {
      opacity: 0,
      y: -30,
      transition: {
        type: 'tween',
        duration:0.4,
      }
    }
  }

  return (
    <motion.article className={musicHolder} variants={articleVariants} key="music">
        <Seo 
          title="Music"
          description="Eliza Shaddad music releases"
        />
        <motion.ul className="albums" key="ul">
          <motion.li key="twyw" className="album" variants={liVariants}>
            <StaticImage
              src="../images/music/twyw-album.jpg"
              alt="Eliza Shaddad - The Woman You Want"
            />
            <h4>The Woman You Want</h4>
            <a href="https://open.spotify.com/album/3tj0F758dlqS3TZjKmtgnM" className="album-link"><FontAwesomeIcon icon={faSpotify} /> Listen on Spotify</a>
            <a href="https://geo.itunes.apple.com/album/id1559818551?mt=1&app=music&at=1l3voFY&ct=project538244224" className="album-link"><FontAwesomeIcon icon={faApple} /> Listen on Apple</a>
          </motion.li>
          <motion.li key="sd" className="album" variants={liVariants} >
            <StaticImage
              src="../images/music/sept-dec.jpg"
              alt="Eliza Shaddad - Sept~Dec"
            />
            <h4>Sept~Dec EP</h4>
            <a href="https://open.spotify.com/album/6tYUyBo0cIL8dCSBsBjKnx?si=l4xUpDK-QMeWtQohnC6cTw" className="album-link"><FontAwesomeIcon icon={faSpotify} /> Listen on Spotify</a>
            <a href="https://music.apple.com/sg/album/sept-dec-single/1488829823" className="album-link"><FontAwesomeIcon icon={faApple} />Listen on Apple</a>
          </motion.li>
          <motion.li key="future" className="album" variants={liVariants} >
            <StaticImage
              src="../images/music/future.jpg"
              alt="Eliza Shaddad - Future"
              />
            <h4>Future</h4>
            <a href="https://open.spotify.com/album/7hj1esMD2AwAmmeXLQfYQW?si=0hnn8JUOTpi55w6rmM1JsQ" className="album-link"><FontAwesomeIcon icon={faSpotify} /> Listen on Spotify</a>
            <a href="https://music.apple.com/sg/album/future/1426049212" className="album-link"><FontAwesomeIcon icon={faApple} />Listen on Apple</a>
          </motion.li>
          <motion.li key="run" className="album" variants={liVariants} >
            <StaticImage
              src="../images/music/run.jpg"
              alt="Eliza Shaddad - Run"
              />
            <h4>Run EP</h4>
            <a href="https://open.spotify.com/album/3YPIgGGoMTcghz7UEuHjpW?si=B2iSlj3oRGqCbCcniEQoIw" className="album-link"><FontAwesomeIcon icon={faSpotify} /> Listen on Spotify</a>
            <a href="https://music.apple.com/sg/album/run-ep/1083120395" className="album-link"><FontAwesomeIcon icon={faApple} />Listen on Apple</a>
          </motion.li>
          <motion.li key="waters" className="album" variants={liVariants}>
            <StaticImage
              src="../images/music/waters.jpg"
              alt="Eliza Shaddad - Waters"
            />
            <h4>Waters EP</h4>
            <a href="https://open.spotify.com/album/1weVuYi7BkklA5dukTOUfj?si=bx6qUqmiSH-WyE3iYGWEiQ" className="album-link"><FontAwesomeIcon icon={faSpotify} /> Listen on Spotify</a>
            <a href="https://music.apple.com/sg/album/waters-ep/871722873" className="album-link"><FontAwesomeIcon icon={faApple} />Listen on Apple</a>
          </motion.li>
        </motion.ul>
    </motion.article>
  )
}
